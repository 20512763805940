$.fn.serializeObject = function()
{
   var o = {};
   var a = this.serializeArray();
   $.each(a, function() {
       if (o[this.name]) {
           if (!o[this.name].push) {
               o[this.name] = [o[this.name]];
           }
           o[this.name].push(this.value || '');
       } else {
           o[this.name] = this.value || '';
       }
   });
   return o;
};
const UIevents ={
    init: function(){
        this.selectType2Event();
        this.bodyClickEvent();
        this.mapEvent();
        this.clickListSearchBtnEvent();
        this.btnEvent();
        this.pagingEvent();
        this.infoSearchEvent();
        this.mainSlideEvent();
        this.mainPopup();
    }
    ,mainPopup: function(){

        var cookieData = document.cookie;
        if(cookieData.indexOf("mainPop=done") < 0){
            $(".mainPopup").show();
        }else {
            $(".mainPopup").hide();
        }

        const setCookie = function(name,value){
            document.cookie = escape(name) + "=" + escape(value);
        }

        const deleteCookie = function(name){
            const expireDate = new Date();
            expireDate.setDate(expireDate.getDate() - 1);
            document.cookie = name + "=" + "; expires=" + expireDate.toGMTString();
        }
        $(".mainPopup .close").on("click", function(){
            //console.log("닫기!")
            $(".mainPopup").hide();
        })
        $(".mainPopup .close_all").on("click", function(){
            //console.log("다시 보지 않기!")
            setCookie("mainPop","done");
            $(".mainPopup").hide();
        })
        
    }
    ,mainSlideEvent: function(){
        var ww = $(window).width();
        var mySwiper = undefined;

        function initSwiper() {
            if (ww > 1280 && mySwiper == undefined) {
                mySwiper = new Swiper(".slide_type5", {
                    slidesPerView: 3,
                    spaceBetween: 20,
                    simulateTouch: true,
                });
            } else if (ww <= 1280 && mySwiper != undefined) {
                mySwiper.destroy();
                mySwiper = undefined;
            }
        }

        initSwiper();

        $(window).on('resize', function () {
            ww = $(window).width();
            initSwiper();
        });
    }
    ,selectType2Event: function(){
        const selectbox = document.querySelector(".select--type2") || null;
        if(!selectbox){
            return false
        }

        const selected_box =  selectbox.querySelector(".select--type2__selected_box");
        const options_box =  selectbox.querySelector(".select--type2__options_box");
        const options = selectbox.querySelectorAll(".select--type2__option");

        selectbox.addEventListener('click',function(){
            if(options_box.style.display==='none'){
                options_box.style.display="block";
                this.classList.add("select--type2--active");
            }else{
                options_box.style.display="none";
                this.classList.remove("select--type2--active");
            }
        });

        options.forEach(option => {
            option.addEventListener('click',function(){
                const value = this.getAttribute('data-value'); 
                const option = this.getAttribute('data-option'); 
                selected_box.textContent= option;
                selected_box.setAttribute("data-value",value);

            });
        });
    }
    ,exceptedClickSelectType2: function(target){
        const selectbox = document.querySelector(".select--type2") || null;
        if(!selectbox){
            return false
        }
        const options_box =  selectbox.querySelector(".select--type2__options_box");
        if (!target.closest('.select--type2')){
            if(options_box.style.display!='none'){
                selectbox.click();
            }
        }
    }   
    ,bodyClickEvent: function(){
        const that= this;
        const body = document.querySelector('body');
        
        body.addEventListener('click',function(e){
            const target = e.target;
            that.exceptedClickSelectType2(target);
            
        });
    }
    ,mapEvent: function() {
        let map = document.querySelectorAll('.map_wrap');
        map.forEach((tabTarget)=>{
          let tabBtn = tabTarget.querySelectorAll('.map_wrap .btn');
          let cont = tabTarget.querySelectorAll('.map_wrap .map_area iframe');
          tabBtn.forEach((target, index)=>{
            // target.classList.add('on');
            target.addEventListener('click',function(){
              removeActiveClasses(tabTarget);
              target.classList.add('on');
              cont[index].classList.add('on');
              UIeventEB.scrollCenter();
            });
          });
        });
       
        function removeActiveClasses(parent) {
          let tabBtn = parent.querySelectorAll('.map_wrap .btn');
          let cont = parent.querySelectorAll('.map_wrap .map_area iframe');
          tabBtn.forEach((btn) => {
              btn.classList.remove("on");
            });
            cont.forEach((contTarget) => {
              contTarget.classList.remove("on");
            });
        }
    }
    ,searchFormSubmit:function(){
		console.log('함수 실행');

		function searchForm_searchstr(){
			let searchStr;
			searchStr = $(".search_box #searchStr").val();
			if(searchStr == ""){
				searchStr = "%20";
			}else{
				searchStr = encodeURIComponent(searchStr);
			}
			return searchStr;
		}

        let searchStr = searchForm_searchstr();
        var locationUrl = USE9.setUrlVar("str",searchStr);
        locationUrl = USE9.setUrlVarInUrl(locationUrl,"page",0);
		location.href=locationUrl;
	}
    ,infoSearchFormSubmit:function(){
		console.log('함수 실행');

		function searchForm_searchstr(){
			let searchStr;
			searchStr = $("#str").val();
			if(searchStr == ""){
				searchStr = "%20";
			}else{
				searchStr = encodeURIComponent(searchStr);
			}
			return searchStr;
		}

        function searchForm_company_select(){
			let companyStr;
			companyStr = $("input[name='company']").val();
			if(companyStr == ""){
				companyStr = "all";
			}else{
				companyStr = encodeURIComponent(companyStr);
			}
			return companyStr;
		}

        function searchForm_brand_select(){
			let brandStr;
			brandStr = $("input[name='brand']").val();
			if(brandStr == ""){
				brandStr = "all";
			}else{
				brandStr = encodeURIComponent(brandStr);
			}
			return brandStr;
		}

        function searchForm_model_select(){
			let modelStr;
			modelStr = $("input[name='model']").val();
			if(modelStr == ""){
				modelStr = "all";
			}else{
				modelStr = encodeURIComponent(modelStr);
			}
			return modelStr;
		}

        function searchForm_part_select(){
			let partStr;
			partStr = $("input[name='part']").val();
			if(partStr == ""){
				partStr = "all";
			}else{
				partStr = encodeURIComponent(partStr);
			}
			return partStr;
		}

        let searchStr = searchForm_searchstr();
        let companyStr = searchForm_company_select();
        let brandStr = searchForm_brand_select();
        let modelStr = searchForm_model_select();
        let partStr = searchForm_part_select();

        let locationUrl = USE9.setUrlVar("str",searchStr);
            locationUrl = USE9.setUrlVarInUrl(locationUrl,"company",companyStr);
            locationUrl = USE9.setUrlVarInUrl(locationUrl,"brand",brandStr);
            locationUrl = USE9.setUrlVarInUrl(locationUrl,"model",modelStr);
            locationUrl = USE9.setUrlVarInUrl(locationUrl,"part",partStr);
            locationUrl = USE9.setUrlVarInUrl(locationUrl,"page",0);
        
        location.href=locationUrl+"#scTop";

	}
    ,infoSearchEvent:function(){
        const that= this;
        // $(document).on("click",".partinquery_submit",function(e){
        //     e.preventDefault();
        //     //$("#partinquery").submit();
        //     that.infoSearchFormSubmit('companySel');
        // })
        // $(document).on("click",".companySel",function(e){
        //     e.preventDefault();
        //     let id = $(this).data("id");
        //     $("input[name='company']").val(id);
        //     //that.infoSearchFormSubmit();
        // })
        // $(document).on("click",".brandSel",function(e){
        //     let id = $(this).data("id");
        //     $("input[name='brand']").val(id);
        //     //that.infoSearchFormSubmit();
        //     if(id !== 'all'){
        //         $(".modelSelText").removeClass('readonly');
        //     }else {
        //         $(".modelSelText").addClass('readonly');
        //     }
        // })
        // $(document).on("click",".modelSel",function(e){
        //     let id = $(this).data("id");
        //     $("input[name='model']").val(id);
        //     //that.infoSearchFormSubmit();
        // })
        // $(document).on("click",".partSel",function(e){
        //     let id = $(this).data("id");
        //     $("input[name='part']").val(id);
        //     //that.infoSearchFormSubmit();
        // })

        $('.companySel').each(function(){
            if($(this).hasClass("option_selected") == true) {
                let sel_text = $(".companySel.option_selected").text();
                $(".companySelText").html(sel_text)
            }
        })
        $('.brandSel').each(function(){
            if($(this).hasClass("option_selected") == true) {
                let sel_text = $(".brandSel.option_selected").text();
                $(".brandSelText").html(sel_text)
            }
        })
        $('.modelSel').each(function(){
            if($(this).hasClass("option_selected") == true) {
                let sel_text = $(".modelSel.option_selected").text();
                $(".modelSelText").html(sel_text)
            }
        })
        $('.partSel').each(function(){
            if($(this).hasClass("option_selected") == true) {
                let sel_text = $(".partSel.option_selected").text();
                $(".partSelText").html(sel_text)
            }
        })

        let brandVal = $(".informationInquiry_page input[name='brand']").val();
        console.log(brandVal,"brandVal")
        if(brandVal == '' || brandVal == 'all'){
            $(".modelSelText").addClass('readonly');
        }else {
            $(".modelSelText").removeClass('readonly');
        }
    }
    ,clickListSearchBtnEvent: function(){
		const that= this;
		$(".listSearchBtn").on("click",function(e){
			console.log('클릭');
			e.preventDefault();
			that.searchFormSubmit();
		});
	}
    ,btnEvent: function(){
        //뉴스 더보기
        $(".news_list .list").slice(0, 12).show(); // select the first 4
        $(".moreBtn").on("click",function(e){ // click event for load more
            e.preventDefault();
            console.log("moreBtn")
            $(".news_list .list:hidden").slice(0, 12).show(); // select next 4 hidden divs and show them
            if($(".news_list .list:hidden").length == 0){ // check if any hidden divs still exist
                // alert("No more divs"); // alert if there are none left
                $('.moreBtn').hide();
            }
        });

        // $(".downloadBtn").on("click", function(e){
        //     e.preventDefault();
        //     alert("준비중입니다.")
        // })

        $(".sharingBtn").on("click", function(e){
            e.preventDefault();
            let url = window.document.location.href;
            $("#link-area").attr("type", "text");
            $("#link-area").val(url);
            $("#link-area").select();
            var success = document.execCommand("copy");
            $("#link-area").attr("type", "hidden");
            if(success){
                alert("링크가 복사되었습니다.");
            }
        })

        // $(".printBtn").on("click", function(e){
        //     e.preventDefault();
        //     let initBody = document.body;

        //     let hiddenHeader = document.querySelector('header');
        //     let hiddenCopyright = document.querySelector('footer');

        //     window.onbeforeprint = function () {
        //         hiddenHeader.style.display = "none";
        //         hiddenCopyright.style.display = "none";
        //        //$('.imgMap-items').css('display', 'none');

        //         document.body = document.querySelector('#print_section');
        //     }

        //     window.onafterprint = function () {
        //         hiddenHeader.style.display = "block";
        //         hiddenCopyright.style.display = "block";
        //         //$('.imgMap-items').css('display', 'block');

        //         document.body = initBody;
        //     } 

        //     window.print();
        // })

        $(".printBtn").on("click", function(e){
            e.preventDefault();
            // console.log("출력!!")
            // var initBody = document.body.innerHTML;
            // window.onbeforeprint = function () {
            //     document.body.innerHTML = document.getElementById("print_section").innerHTML;
            // }
            // window.onafterprint = function () {
            //     document.body.innerHTML = initBody;
            // }
            window.print();
        })

        $(".btn_area .global").on("click", function(e){
            e.preventDefault();
            // console.log("global_btn")
            // if($(".global_btn").css("display")==="none"){
            //     $(".global_btn").slideDown();
            // }else {
            //     $(".global_btn").slideUp();
            // }
        })

    }
    ,pagingEvent:function(){
        const button = $(".btnPage");
        button.on("click",function(){
            const hash = $(location).attr('hash');  
            const val = $(this).attr("data-value");
            let locationUrl =  USE9.setUrlVar("page",val);

            if($("#scTop").length && hash !== '#scTop'){
                location.href=locationUrl+"#scTop";
            }else {
                location.href=locationUrl;
            }
        })
    }  
    ,
}

const register ={
    submitRegister: function(el){
        const form = document.forms[el];
        const required = ['type','name','tel','email','title', 'contents', 'agree'];
        const posturl = '/post/complaint'
        //const scrollTop = $(this).scrollTop();
        let isPass  = this.validateSubmit(form,required).isPass;
        if(isPass){
           this.sendFormData(el,posturl);              
        }else{
            alert(this.validateSubmit(form,required).errMsg);
            this.validateSubmit(form,required);

            //const position = $(".complaint_form").offset().top - 90;

            // $("html, body").stop(true,true).animate({scrollTop:position},'easeOutExpo',function(){
            //     //scrollEventFlag = true;
            // });
            // document.querySelector(".motion_top").scrollIntoView({
            //     behavior: "smooth",
            // });
        }
    }
    ,sendFormData: function(el,posturl){
        const formData  = $(`#${el}`).serializeObject();
        
        axios.post(posturl,formData)
        .then((result)=>{
            alert('접수가 완료되었습니다. 확인 후 이메일로 답변드리겠습니다.')
            location.reload();
        })
        .catch((error)=>{
            console.error(error);
            alert('서버접속 에러, 잠시후 다시 시도해주세요.')
        }) 
            
    }
    ,validateSubmit: function(form,required){ // el, arrayk
        const that = this;
        const forms = form;
        let result = {};

        // 필수 조건 없으면 패스
        if(!required){ 
            result.isPass = true;
            return result;
        }

        // 필수 조건 있을시, validation 적용
        for (let i = 0; i < required.length; i++) {
            const el = required[i];
            //let errorText = $('.'+el+'_error');

            // 민원유형 검증
            if(el==='type'){
                if(!forms.elements[el].value){
                    result.isPass=false;
                    result.errMsg='유형을 선택해주세요';
                    //alert(result.errMsg);
                    //errorText.text(result.errMsg);	
                    //errorText.parent('.form_contents').addClass('error_message');
                    break;
                }else{
                   // errorText.text('');
                    result.isPass= true;
                }
            }
            
            // 이름 검증
            if(el==='name'){
                if(!forms.elements[el].value){
                    result.isPass=false;
                    result.errMsg='이름을 입력해주세요';
                    //alert(result.errMsg);
                    //errorText.text(result.errMsg);
                    //errorText.parent('.form_contents').addClass('error_message');
                    break;
                }else{
                    //errorText.text('');
                    //errorText.parent('.form_contents').removeClass('error_message');
                    result.isPass= true;
                }
            }

            // 연락처 검증
            if(el==='tel'){
                if(!forms.elements[el].value){
                    result.isPass=false;
                    result.errMsg='연락처를 입력해주세요';
                    //alert(result.errMsg);
                    //errorText.text(result.errMsg);
                    //errorText.parent('.form_contents').addClass('error_message');
                    break;
                }else{
                    //errorText.text('');
                    //errorText.parent('.form_contents').removeClass('error_message');
                    result.isPass= true;
                }
            }

            //이메일 검증
            if(el==='email'){
                result= that.validateEmail(forms.elements[el].value);
                if(!result.isPass){
                    //alert(result.errMsg);
                    //errorText.text(result.errMsg);
                    //errorText.parent('.form_contents').addClass('error_message');
                    break;
                }
                //errorText.parent('.form_contents').removeClass('error_message');
            }

            // 제목 검증
            if(el==='title'){
                if(!forms.elements[el].value){
                    result.isPass=false;
                    result.errMsg='제목을 입력해주세요';
                    //alert(result.errMsg);
                    //errorText.text(result.errMsg);
                    //errorText.parent('.form_contents').addClass('error_message');
                    break;
                }else{
                    //errorText.text('');
                    //errorText.parent('.form_contents').removeClass('error_message');
                    result.isPass= true;
                }
            }

            // 내용 검증
            if(el==='contents'){
                if(!forms.elements[el].value){
                    result.isPass=false;
                    result.errMsg='내용을 입력해주세요';
                    //alert(result.errMsg);
                    //errorText.text(result.errMsg);
                    //errorText.parent('.form_contents').addClass('error_message');
                    break;
                }else{
                    //errorText.text('');
                    //errorText.parent('.form_contents').removeClass('error_message');
                    result.isPass= true;
                }
            }

            // 개인정보수집동의 검증
            if(el==='agree'){
                result = that.validateAgree(forms.elements[el].checked);
                if(!result.isPass){
                    //alert(result.errMsg);
                    break;
                }
            }
            
        }

        return result;
        

    }
    ,validateEmail:(value)=>{
        let result ={};
        result.isPass= false;
        result.errMsg= '이메일을 확인해주세요.'
        const regExp = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i;;
        if(!value){
            result.errMsg = "이메일을 입력해주세요."
        }
        if (value.match(regExp)){
            result.isPass = true;
            result.errMsg = null;
        }
        return result
    }
    ,validateAgree:(value)=>{
        let result ={};
        result.isPass= false;
        result.errMsg= '개인정보 수집 및 이용에 대해서 동의해주세요.';
        if(value){
            result.isPass= true;
            result.errMsg=null;
        }
        return result;
    }
    
    
}


// const comments ={
//     init:function(){
//         this.onClickAddComment();
//     }
//     ,val: {
//         sectionID:''
//         ,value:''
//         ,coord:{}
//         ,created_at:''
//     }
//     ,onClickAddComment:function(){
//         const that =this;
//         const button  = $(".addImageComment");
      
//         button.on("click",function(){
//             const parent = $(this).parents(".addImageCommentsIssue");
//             const view = parent.find(".addImageCommentsArea");

//             if(!$(this).hasClass("btn--type2A--active")){
//                 $(this).addClass("btn--type2A--active");

//                 const viewW = view.width(),
//                     viewH = view.height();
//                 view.on('click',function(e){
//                     console.log(e);
//                     //1-1. 클릭 지점 좌표 퍼센트 추출
//                     let pcntX, pcntY;
//                     const x = e.offsetX;
//                     const y = e.offsetY;
//                     pcntX = x/viewW*100;
//                     pcntY = y/viewH*100;
//                     pcntX = pcntX.toFixed(1);
//                     pcntY = pcntY.toFixed(1);
//                     that.val.coord.x = pcntX;
//                     that.val.coord.y = pcntY;
//                     console.log(that.val.coord);
                    
//                     //1-2. 생성날짜 추출 
//                     const date = new Date();
//                     that.val.created_at = date;
//                     console.log(that.val.created_at);
                    
//                     //1-3. sectionID값 추출
//                     const sectionID = parent.attr("data-id");
//                     that.val.sectionID = sectionID;
//                     console.log(that.val.sectionID);
                    
//                     //2-1. 클릭 지점에 버튼 삽입
//                     //  html 변경 예정
//                     const comments_html = ` <div class="comment" style="position:absolute; left:${pcntX}%; top:${pcntY}%;">
//                     <div class="comment__inner">
//                         <span class="comment__order">1</span>
//                         <div class="comment__select_box">
//                             <ul>
//                                 <li>
//                                     <div class="checkbox">
//                                         <input type="checkbox" id="선택1" name="선택1">
//                                         <label for="선택1"><span></span>선택1</label>
//                                     </div>
//                                 </li>
//                                 <li>
//                                     <div class="checkbox">
//                                         <input type="checkbox" id="선택2" name="선택2">
//                                         <label for="선택2"><span></span>선택2</label>
//                                     </div>
//                                 </li>
//                             </ul>
//                             <div class="comment__button_box">
//                                 <button class="comment__confirm"><span>선택완료</span></button>
//                                 <button class="comment__cancle"><span>닫기</span></button>
//                             </div>
//                         </div>
//                     </div>
//                     </div>`;
//                     view.prepend(comments_html);

//                     //2-2. 코멘츠 더하기 기능 막기
//                     view.off("click");
//                     //2-3. 버튼 클릭 이벤트 실행
//                     const button = $(".comment");
//                     button.on("click",function(){
//                         const popup = $(this).find(".comment__select_box");
//                         if(popup.css("display")==="none"){
//                             popup.show();
//                             console.log("팝업 오픈");
                            
//                         }else{
//                             popup.hide();
//                             console.log("팝업 클로즈");
//                         }
//                     });
//                     //3. 
                    
//                 });    
//             }else{
//                 $(this).removeClass("btn--type2A--active");
//                 //2. 코멘츠 더하기 기능 제거
//             }
                     
//         });
//     }
//     ,onClickCommentButton: function(e){
//         console.log('버튼 클릭');

//         const button = $(".comment");
//         button.on("click",function(){
//             const popup = $(this).find(".comment__select_box");
//             if(popup.css("display")==="none"){
//                 popup.show();
//                 console.log("팝업 오픈");
                
//             }else{
//                 popup.hide();
//                 console.log("팝업 클로즈");
//             }
//         });
        
        
        
//     }
// }

const formSubmit = {
    init: function(){
        this.typeSelect();
        this.complete();

        const scrollEvent = function(){
            document.querySelector(".motion_top").scrollIntoView({
                behavior: "smooth",
            });
        }

        let fromBtn = document.getElementById('complaintSubmit');
        if(fromBtn){
            fromBtn.addEventListener("click", function(e){


                e.preventDefault();

                

                let type = document.getElementById('type');
                let name = document.getElementById('name');
                let tel = document.getElementById('tel');
                let email = document.getElementById('email');
                let title = document.getElementById('title');
                let contents = document.getElementById('contents');
                let agree = document.getElementById('agree');

                let lng = $(this).data("lng");

                if(type.value == ''){
                    if(lng == "en"){
                        alert("Please select a type.")
                    }else {
                        alert("유형을 선택하세요.!")
                    }
                    scrollEvent();
                    return false
                }

                if(name.value == ''){
                    if(lng == "en"){
                        alert("Please enter your name.")
                    }else {
                        alert("이름을 입력해주세요.")
                    }
                   
                    scrollEvent();
                    return false
                }

                if(tel.value == ''){
                    if(lng == "en"){
                        alert("Please enter your contact information.")
                    }else {
                        alert("연락처를 입력해주세요.")
                    }
                    
                    scrollEvent();
                    return false
                }

                //var telRegExp = /^01(0|1|[6-9])[0-9]{3,4}[0-9]{4}$/;
                var telRegExp = /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})[0-9]{3,4}[0-9]{4}$/;

                if(!telRegExp.test(tel.value)){
                    if(lng == "en"){
                        alert("Please check your contact information.")
                    }else {
                        alert("연락처를 확인해주세요.")
                    }
                    
                    scrollEvent();
                    return false
                }

                if(email.value == ''){
                    if(lng == "en"){
                        alert("Please enter your email.")
                    }else {
                        alert("이메일을 입력해주세요.")
                    }
                    
                    scrollEvent();
                    return false
                }

                const emailRegExp = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i;

                if (!emailRegExp.test(email.value)) {
                    if(lng == "en"){
                        alert("Please check your email.")
                    }else {
                        alert("이메일을 확인해주세요.")
                    }
                    
                    scrollEvent();
                    return false
                }

                if(title.value == ''){
                    if(lng == "en"){
                        alert("Please enter the title.")
                    }else {
                        alert("제목을 입력해주세요.")
                    }
                    
                    scrollEvent();
                    return false
                }

                if(contents.value == ''){
                    if(lng == "en"){
                        alert("Please enter the contents.")
                    }else {
                        alert("내용을 입력해주세요.")
                    }
                    
                    scrollEvent();
                    return false
                }

                if(!agree.checked){
                    if(lng == "en"){
                        alert("Please agree to the collection and use of personal information.")
                    }else {
                        alert("개인정보 수집 및 이용에 대해서 동의해주세요.")
                    }
                    
                    scrollEvent();
                    return false
                }

                //const formData  = document.getElementById('complaint');
                //console.log("!!!!?",$('#complaint'))
                const formData  = $('#complaint').serializeObject();
                const posturl = '';

                console.log(formData,"formData@@@@@")

                axios({
                    method:'post',
                    url:'https://util.use9.kr/mail/kapamail',
                    headers: {
                        "Content-Type": "application/json",
                    },
                    data: formData,
                })
                .then((result)=>{
                    //console.log('요청성공')
                    console.log(result,"요청성공")
                    const modal = document.querySelector('.modal_type1');
                    modal.style.display = 'block'
                })
                .catch((error)=>{
                    //console.log('요청실패')
                    console.log(error)  
                })
            });
        }
    }
    ,typeSelect: function(){
        let elem = document.querySelectorAll('.type_opt');
        let type_input = document.getElementById('type');
        let i;

        for (i = 0; i < elem.length; i++) {
            elem[i].addEventListener("click", function(e){
                console.log(e.target.dataset.option);
                type_input.value = e.target.dataset.option;
            });
        }
    }
    ,complete: function(){
        const okBtn = document.querySelector('.complete_btn');
        const modal = document.querySelector('.modal_type1');
        if(okBtn){
            okBtn.addEventListener("click", function(e){
                modal.style.display = 'none';
                location.reload();
            })
        }
        
    }
}

document.addEventListener("DOMContentLoaded", () => {
    UIevents.init();

    formSubmit.init();

});