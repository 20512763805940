const UIeventEB = {
    init: function() {
        this.gnb();
        this.accodianClick();
        this.selectType1Event();
        // this.mouseUpEvent();
        this.accList();
        this.tabType1();
        this.tabType2();
        this.scrollCenter();
        this.headerEvent();
        this.rollingBanner();
        this.historyScroll();
        this.gnbHover();
        this.lngClick();
        // this.accListClick();
    }
    ,historyScroll: function() {
      var list = document.querySelectorAll('.history_list .list');
      if(list != null) {
        var winWChk = '';
        window.addEventListener('load', function(){
          var winW = window.innerWidth;
          if(winWChk != 'mo' && winW <= 767){ //모바일
              winWChk = 'mo';
              motion();
          }
          else if(winWChk != 'ta' && winW <= 1279  && winW >= 768) { //테블릿
            winWChk = 'ta';
            motion();
          }
    
          else if(winWChk != 'pc' && winW >= 1280){ //PC
              winWChk = 'pc';
              motion();
          }
      });
        window.addEventListener('resize', function(){
           var winW = window.innerWidth;
            if(winWChk != 'mo' && winW <= 767){ //모바일
                winWChk = 'mo';
                motion()
            }
            else if(winWChk != 'ta' && winW <= 1279  && winW >= 768) { //테블릿
              winWChk = 'ta';
              motion()
            }
      
            else if(winWChk != 'pc' && winW >= 1280){ //PC
                winWChk = 'pc';
                motion()
            }
        });
        function motion() {
          list.forEach((target)=>{
            var fill = target.querySelector('.fill');
            var top = target.offsetTop;
            var height = target.offsetHeight + top;
            // console.log(height, top)
            window.addEventListener('scroll', function() {
                
                  // 전체 페이지 높이와 브라우저 창 높이를 가져옴
                  var pageHeight = document.documentElement.scrollHeight;
                  var windowHeight = window.innerHeight / 1.5;
                
                  var scrollPosition = window.scrollY + windowHeight;
                  var fillRatio = (scrollPosition - top) / target.offsetHeight;
                  var result = fillRatio * 100;
                  if(result <= 0) {
                    result = 0;
                  }
                  if(result >= 100) {
                    result = 100;
                  }
                  
                  // console.log(result)
                  fill.style.height = result + '%'
                 if(scrollPosition >= top) {
                  target.classList.add("active");
                 }else {
                  target.classList.remove("active");
                 }
                
            });
          });
        }
      }
    }
  //   ,rollingBanner: function() {
  //     var originalID, cloneID;
  //     let roller = document.querySelector(".roller");
  //     if(roller != null) {
  //       roller.id = "roller1";

  //       let clone = roller.cloneNode(true);
  //       clone.id = "roller2";
  //       document.querySelector(".rolling_banner").appendChild(clone);
  
  //       flowBannerAct();
  //       var winWChk = '';
  //       //반응형 :: 디바이스가 변경 될 때마다 배너 롤링 초기화
  //       window.addEventListener('resize', function(){
  //         var winW = window.innerWidth;
  //          if(winWChk != 'mo' && winW <= 767){ //모바일
  //              winWChk = 'mo';
              
  //              flowBannerAct();
  //          }
  //          else if(winWChk != 'ta' && winW <= 1279  && winW >= 768) { //테블릿
  //            winWChk = 'ta';
            
  //            flowBannerAct();
  //          }
     
  //          else if(winWChk != 'pc' && winW >= 1280){ //PC
  //              winWChk = 'pc';
  //              flowBannerAct();
  //          }
  //      });
       
        
  //       function flowBannerAct() {
  //           stopRoller();
  //           //위치 지정
  //           document.querySelector("#roller1").style.left = "0px";
  //           document.querySelector("#roller2").style.left = document.querySelector(".roller ul").offsetWidth+'px';
            
  //           roller.classList.add('original');
  //           clone.classList.add('clone');
            
  //           let rollerWidth = document.querySelector('.roller ul').offsetWidth;//회전 배너 너비값
  //           let betweenDistance = 1;//이동 크기 - 정수여야 함
            
  //           //롤링 시작
  //           function startRoller(){
  //             stopRoller();
  //               originalID = setInterval(function(){
  //                   betweenRollCallback(betweenDistance, document.querySelector('#roller1'));
  //               },parseInt(1000/50));
  //               cloneID = setInterval(function(){
  //                   betweenRollCallback(betweenDistance, document.querySelector('#roller2'));
  //               },parseInt(1000/50));
                
  //           }
            
  //           //롤링 정지
  //           function stopRoller(){
  //               clearInterval(originalID);
  //               clearInterval(cloneID);
  //           }
            
  //           //마우스 호버시 롤링이 멈추었다 벗어나면 다시 롤링이 되도록 처리
  //           document.querySelector("#roller1").addEventListener('mouseenter',function(){
  //             stopRoller();
  //           });
  //           document.querySelector("#roller2").addEventListener('mouseenter',function(){
  //             stopRoller();
  //           });
  //           document.querySelector("#roller1").addEventListener('mouseleave',function(){
  //             startRoller();
  //           });
  //           document.querySelector("#roller2").addEventListener('mouseleave',function(){
  //             startRoller();
  //           });
          
          
  //           //인터벌 애니메이션 함수(공용)
  
  //           function betweenRollCallback(d, roller){
  //               let left = parseInt(roller.style.left);
  //               // console.log(left)
  //               roller.style.left = (left - d)+'px';//이동
  //               //조건부 위치 리셋
  //               if(rollerWidth + (left - d) <= 0){
  //                   roller.style.left = rollerWidth+'px';
  //               }
  //           }
            
  //           startRoller();//롤링 초기화
  //       }
  
  //     }
    
      
  // }
  ,rollingBanner: function() {
    let roller = document.querySelectorAll(".roller");
    roller.forEach((target,i)=>{
      var originalID, cloneID;
      target.id = "rollerType"+i+"_1";

      let clone = target.cloneNode(true);
      clone.id = "rollerType"+i+"_2";
      target.parentElement.appendChild(clone);

      flowBannerAct();
      var winWChk = '';
      //반응형 :: 디바이스가 변경 될 때마다 배너 롤링 초기화
      window.addEventListener('resize', function(){
        var winW = window.innerWidth;
         if(winWChk != 'mo' && winW <= 767){ //모바일
             winWChk = 'mo';
            
             flowBannerAct();
         }
         else if(winWChk != 'ta' && winW <= 1279  && winW >= 768) { //테블릿
           winWChk = 'ta';
          
           flowBannerAct();
         }
   
         else if(winWChk != 'pc' && winW >= 1280){ //PC
             winWChk = 'pc';
             flowBannerAct();
         }
     });
     
      
      function flowBannerAct() {
          stopRoller();
          //위치 지정
          document.querySelector("#rollerType"+i+"_1").style.left = "0px";
          document.querySelector("#rollerType"+i+"_2").style.left = target.parentElement.querySelector(".roller ul").offsetWidth+'px';
          
          target.classList.add('original');
          clone.classList.add('clone');
          
          let rollerWidth = target.parentElement.querySelector('.roller ul').offsetWidth;//회전 배너 너비값
          let betweenDistance = 1;//이동 크기 - 정수여야 함
          // console.log(betweenDistance)
          //롤링 시작
          function startRoller(num){
            stopRoller();
              originalID = setInterval(function(){
                  betweenRollCallback(betweenDistance, document.querySelector("#rollerType"+i+"_1"));
              },parseInt(num));
              cloneID = setInterval(function(){
                  betweenRollCallback(betweenDistance, document.querySelector("#rollerType"+i+"_2"));
              },parseInt(num));
              
          }
          
          //롤링 정지
          function stopRoller(){
              clearInterval(originalID);
              clearInterval(cloneID);
          }
          
          //마우스 호버시 롤링이 멈추었다 벗어나면 다시 롤링이 되도록 처리
          document.querySelector("#rollerType"+i+"_1").addEventListener('mouseenter',function(){
            // if(i == 0) {}
            startRoller((1000/20));
          });
          document.querySelector("#rollerType"+i+"_2").addEventListener('mouseenter',function(){
            startRoller((1000/20));
          });
          document.querySelector("#rollerType"+i+"_1").addEventListener('mouseleave',function(){
            if(i == 0) {

              startRoller((1000/50));
            }
            else if (i == 1) {

              startRoller((1000/55));
            }
          });
          document.querySelector("#rollerType"+i+"_2").addEventListener('mouseleave',function(){
            
            if(i == 0) {

              startRoller((1000/50));
            }
            else if (i == 1) {

              startRoller((1000/55));
            }
          });
        
        
          //인터벌 애니메이션 함수(공용)

          function betweenRollCallback(d, target){
              let left = parseInt(target.style.left);
              // console.log(left)
              target.style.left = (left - d)+'px';//이동
              //조건부 위치 리셋
              if(rollerWidth + (left - d) <= 0){
                target.style.left = rollerWidth+'px';
              }
          }
          if(i == 0) {

            startRoller((1000/50));
          }
          else if (i == 1) {

            startRoller((1000/55));
          }//롤링 초기화
      }
    });
    
    
}
    ,headerEvent: function() {
      let lastScrollY = 0;
      let header = document.querySelector('.header');
      window.addEventListener('scroll', e => {
        const scrollY = window.scrollY;
        const scrollUp = scrollY < lastScrollY;
        if ( scrollY > 100) {
          header.classList.add("hide");
        }else {
          header.classList.remove('hide')
        }
        if ( scrollY > 100 &&  scrollUp) {
          header.classList.add('blur')
        } else {
          header.classList.remove('blur')
        }
        lastScrollY = scrollY;
      });
    }
    ,gnb: function() {
        //gnb 
        const menuBtn = document.querySelector(".ham_btn");
        let isMenuOpen = false;
        const m_gnb = document.querySelector(".header nav");
        const logo = document.querySelector('.header .logo');
        // if (menuBtn != )
        menuBtn.addEventListener("click", function() {
          if (!isMenuOpen) {
              menuBtn.classList.add("close_btn");
              menuBtn.classList.add("on");
              document.querySelector('html').style.overflow = 'hidden';
              document.querySelector('body').style.overflow = 'hidden';
            //    $("html body").css({overflow: "hidden"});
            m_gnb.classList.add('on');
            logo.classList.add('on')
            //   $(m_gnb).addClass("on");
            //   $(".mobile_wrap .logo").addClass("on");
          } else {
            menuBtn.classList.remove("close_btn");
            menuBtn.classList.remove("on");
           
            document.querySelector('html').style.overflow = 'visible';
            document.querySelector('body').style.overflow = 'visible';
            m_gnb.classList.remove('on');
            logo.classList.remove('on')
          }
          isMenuOpen = !isMenuOpen;
        });
    }
    ,accodianClick: function() {
        var accodianBtn = document.querySelectorAll('.acc_menu .accodian_btn');
        var i;
        var panel = document.querySelectorAll(".acc_menu .panel");
      
        for (i = 0; i < accodianBtn.length; i++) {
            accodianBtn[i].addEventListener("click", function() {
                var that = this;
                var parent = that.parentElement.parentElement;
                // console.log(parent)
                var activePanel = this.nextElementSibling;
                // accodianBtn.removeClass("active");
                // $(".acc_menu").removeClass("active")
                if (activePanel.style.maxHeight) {
                    activePanel.style.maxHeight = null;
                    that.classList.remove("active");
                    
                }else {
                    // activePanel.style.maxHeight = '';
                    // parent.classList.add("active");
                removeActiveClasses(parent);
                    that.classList.add("active");
                    activePanel.style.maxHeight = activePanel.scrollHeight + "px";
                }
          });
        
        }

        function removeActiveClasses(parent) {
            console.log(parent,"실행???")
          var btn = parent.querySelectorAll('.accodian_btn');
          var accPanel = parent.querySelectorAll('.panel');
              btn.forEach((btn) => {
              btn.classList.remove("active");
            });
            accPanel.forEach((panels) => {
                panels.style.maxHeight = null;
              });
        }
    }
    ,lngClick: function() {
        var accodianBtn = document.querySelectorAll('.header .global');
        var i;
        var panel = accodianBtn.nextSibling;
      
        for (i = 0; i < accodianBtn.length; i++) {
            accodianBtn[i].addEventListener("click", function() {
                var that = this;
                var parent = that.parentElement.parentElement;
                // console.log(parent)
                var activePanel = this.nextElementSibling;
                // accodianBtn.removeClass("active");
                // $(".acc_menu").removeClass("active")
                if (activePanel.style.maxHeight) {
                    activePanel.style.maxHeight = null;
                    that.classList.remove("active");
                    
                }else {
                    // activePanel.style.maxHeight = '';
                    // parent.classList.add("active");
                    //removeActiveClasses(parent);
                    that.classList.add("active");
                    activePanel.style.maxHeight = activePanel.scrollHeight + "px";
                }
          });
        
        }

        function removeActiveClasses(parent) {
          var btn = parent.querySelectorAll('.accodian_btn');
          var accPanel = parent.querySelectorAll('.panel');
              btn.forEach((btn) => {
              btn.classList.remove("active");
            });
            accPanel.forEach((panels) => {
                panels.style.maxHeight = null;
              });
        }
    }
    ,selectType1Event: function(){
        var options;
      const that= this;
      const selectbox = document.querySelectorAll(".select_type1") || null;

      document.querySelector("body").addEventListener("click", function(e) {
        // console.log(e.target.offsetParent.className)
        var clickTarget = e.target.offsetParent;
        // console.log(clickTarget)
        if(clickTarget != null) {
          if(clickTarget.classList.contains('select_type1')) {
            
           } else {
            selectbox.forEach(select => {
              options =  select.querySelector(".options_box");
              
              options.style.display="none";
              select.classList.remove('on');
      
            })
           }
        }
      
      });

      if(!selectbox.length){
          return false
      }
      else {
        selectbox.forEach(box => {
          let selected =  box.querySelector(".selected");
        //   options =  selectbox.querySelector(".options_box");
        box.addEventListener('click',function(){
            // UIeventEB.selectType1Close();
            options =  box.querySelector(".options_box");
            // console.log(options.style.display)
              if(options.offsetWidth > 0 && options.offsetHeight > 0){
                
                options.style.display="none";
                box.classList.remove('on');
              } else {
                removeActiveClasses(box);
              }
          });

         
          that.selectType1OptionsEvent(box,null);
        });


      }
         function removeActiveClasses(target) {
          selectbox.forEach(select => {
            options =  select.querySelector(".options_box");
            // console.log(options)
            if(select != target) {
              options.style.display="none";
              select.classList.remove('on');
            }
            if(select == target) {

              options.style.display="block";
              select.classList.add('on');
            }
    
          })
        }
      
      
  }
  ,selectType1OptionsEvent :function(selectbox,callback){
    
    const selected_box =  selectbox.querySelector(".selected");
    const options =  selectbox.querySelectorAll(".option");
// console.log(options)
    options.forEach(option => {
        option.addEventListener('click',function(){
            const value = this.getAttribute('data-value'); 
            const option = this.getAttribute('data-option'); 
            selected_box.textContent= option;
            selected_box.classList.remove("disabled");
            selected_box.setAttribute("data-value",value);
            if (callback){
                callback(value);
            }
            // UIeventEB.selectType1Close();
        });
    });
  }
  ,selectType1Close: function() {
    const selectbox1 = document.querySelectorAll(".select_type1") || null;

    if(!selectbox1.length){
        return false
    }
    selectbox1.forEach((box) => {
        // console.log(box.querySelector(".options_box"));
        var option = box.querySelector(".options_box");
        // console.log(option)
        option.style.display="none";
    });
  }
  ,mouseUpEvent: function() {
    var selectbox = document.querySelectorAll(".select_type1") || null;
    if(!selectbox.length){
        return false
    }
    // document.addEventListener('mouseup', (e) => {
    //     // UIeventEB.selectType1Close();
    //     if(selectbox.has(e.target).length === 0) {
    //         console.log(e.target)
    //         // UIeventEB.selectType1Close();
    //     }
    // });
    
  }
  ,accList: function() {
    let listWrap = document.querySelectorAll('.list_type12');
    // let list = document.querySelectorAll('.list_type12_list');
   
    listWrap.forEach((target, i)=>{
      let activeList;
      let nextList;
      let prevList;
      let next = target.querySelector('.list_type12_next');
      let prev = target.querySelector('.list_type12_prev');

      let first = target.querySelector('.inner').firstElementChild;
      let last = target.querySelector('.inner').lastElementChild;
      // console.log(next, prev)

      // list.forEach()
      next.addEventListener('click',function(){
        activeList = target.querySelectorAll('.list_type12_list.active');
        nextList = activeList[0].nextElementSibling;
        // console.log(activeList[0].nextElementSibling)
        if(nextList != null) {
          activeList[0].classList.remove('active');
          nextList.classList.add("active");
        }
        if (nextList == first && nextList != last) {
          prev.classList.add('disabled');
          next.classList.remove('disabled');
        }
        else if(nextList == last && nextList != first) {
          prev.classList.remove('disabled');
          next.classList.add('disabled');
        }
        else {
          
          prev.classList.remove('disabled');
          next.classList.remove('disabled');
        }
        
      });
      prev.addEventListener('click',function(){
        activeList = target.querySelectorAll('.list_type12_list.active');
        prevList = activeList[0].previousElementSibling ;
        // console.log(prevList)
        if(prevList != null) {
          activeList[0].classList.remove('active');
          prevList.classList.add("active");
        }
        if (prevList == first && prevList != last) {
          prev.classList.add('disabled');
          next.classList.remove('disabled');
        }
        else if(prevList == last && prevList != first) {
          prev.classList.remove('disabled');
          next.classList.add('disabled');
        }
        else {
          
          prev.classList.remove('disabled');
          next.classList.remove('disabled');
        }
      });
    });
  }
  ,accListClick: function(that) {
    // console.log(that)
    let inner = document.querySelector('.list_type12 .inner');
    let list = document.querySelectorAll('.list_type12 .list_type12_list');
// console.log(inner.firstElementChild)
    let first = inner.firstElementChild;
    let last = inner.lastElementChild;

    let next = document.querySelector('.list_type12_next');
    let prev = document.querySelector('.list_type12_prev');
    if (that == first && that != last) {
      prev.classList.add('disabled');
      next.classList.remove('disabled');
    }
    else if(that == last && that != first) {
      prev.classList.remove('disabled');
      next.classList.add('disabled');
    }
    else {
      
      prev.classList.remove('disabled');
      next.classList.remove('disabled');
    }
    list.forEach((target)=>{
      target.classList.remove('active');
    });
    that.classList.add("active");
  }
  ,tabType1: function() {
    let tab = document.querySelectorAll('.tab_area');
    tab.forEach((tabTarget)=>{
      let tabBtn = tabTarget.querySelectorAll('.tab_area .tab_btn');
      let cont = tabTarget.querySelectorAll('.tab_cont_area .cont');
      tabBtn.forEach((target, index)=>{
        // target.classList.add('on');
        target.addEventListener('click',function(){
          removeActiveClasses(tabTarget);
          target.classList.add('on');
          cont[index].classList.add('on');
          UIeventEB.scrollCenter();
        });
      });
    });
   
    function removeActiveClasses(parent) {
      let tabBtn = parent.querySelectorAll('.tab_area .tab_btn');
      let cont = parent.querySelectorAll('.tab_cont_area .cont');
      tabBtn.forEach((btn) => {
          btn.classList.remove("on");
        });
        cont.forEach((contTarget) => {
          contTarget.classList.remove("on");
        });
    }
  }
  ,gnbHover: function() {
    const gnbList = document.querySelectorAll('.header .gnb>li');
    const activeList = document.querySelector('.header .gnb>li.on');
    const header = document.querySelector('.header');
    gnbList.forEach((target)=>{
      target.addEventListener('mouseenter',function(){
        removeActiveClasses();
        target.classList.add("on");
      });
    });
   
    // header.addEventListener('mouseenter', function(){
      
    //   header.classList.add("on");
    // });
    header.addEventListener('mouseleave', function(){
      removeActiveClasses();
      // activeList.classList.add('on');
    });
    function removeActiveClasses() {
      gnbList.forEach((target)=>{
        target.classList.remove("on");
      });
    }
  }
  ,tabType2: function() {
    let tab = document.querySelectorAll('.slide_menu_area');
    tab.forEach((tabTarget)=>{
      let tabBtn = tabTarget.querySelectorAll('.menu .list p');
      let menu = tabTarget.querySelector('.menu');
      let cont = tabTarget.querySelectorAll('.sub .list');
      let closeBtn = tabTarget.querySelectorAll('.sub .list p');
      tabBtn.forEach((target, index)=>{
        // target.classList.add('on');
        target.addEventListener('click',function(){
          // removeActiveClasses(tabTarget);
          // target.classList.add('on');
          menu.classList.add('on');
          cont[index].classList.add('on');
        });
      });
      
      closeBtn.forEach((btn)=>{
        btn.addEventListener('click',function(){
          removeActiveClasses(tabTarget);
        });
      });
    });
    function removeActiveClasses(parent) {
      let cont = parent.querySelectorAll('.sub .list');
      let menu = parent.querySelector('.menu');
        cont.forEach((contTarget) => {
          contTarget.classList.remove("on");
        });
        menu.classList.remove('on');
        
    }
  }
  ,scrollCenter: function() {
    // console.log("!")
    const tabCont = document.querySelectorAll('.tab_area_type2 .cont .inner');
    tabCont.forEach((target,i)=>{
      var winWChk = '';
      tabScrollCenter(target);
      window.addEventListener('load', function(){
        var winW = window.innerWidth;
        if(winWChk != 'mo' && winW <= 767){ //모바일
            winWChk = 'mo';
            
            tabScrollCenter(target);
        }
        else if(winWChk != 'ta' && winW <= 1279  && winW >= 768) { //테블릿
          winWChk = 'ta';
          tabScrollCenter(target);
        }
  
        else if(winWChk != 'pc' && winW >= 1280){ //PC
            winWChk = 'pc';
            tabScrollCenter(target);
        }
    });
      window.addEventListener('resize', function(){
         var winW = window.innerWidth;
          if(winWChk != 'mo' && winW <= 767){ //모바일
              winWChk = 'mo';
              
              tabScrollCenter(target);
          }
          else if(winWChk != 'ta' && winW <= 1279  && winW >= 768) { //테블릿
            winWChk = 'ta';
            tabScrollCenter(target);
          }
    
          else if(winWChk != 'pc' && winW >= 1280){ //PC
              winWChk = 'pc';
              tabScrollCenter(target);
          }
      });
     

    });
    function tabScrollCenter(target) {
      var middle = target.children[Math.floor((target.children.length - 1) / 2)];
      target.scrollLeft = middle.offsetLeft +
        middle.offsetWidth / 2 - target.offsetWidth / 2;
    }
  }
 
}
const motionEvent = {
  init: function() {
    this.motionType1();
    this.motionType2();
    this.motionType3();
    this.motionType4();
  }
  ,motionType1: function() {
    
    function showMotion(entry) {
      // console.log(entry.target)
      let winWChk = '';
      const motionType1 = entry.target;
      const motionType1Items = motionType1.querySelectorAll('.item');
      let winW = window.innerWidth;

      let itemsPerRow;

      if(winWChk != 'mo' && winW <= 767){ //모바일
        winWChk = 'mo';
        // removeActiveClasses(entry)
        itemsPerRow = 1;
      }
      else if(winWChk != 'ta' && winW <= 1279  && winW >= 768) { //테블릿
        winWChk = 'ta';
        // removeActiveClasses(entry)
        itemsPerRow = 2;
      }
  
      else if(winWChk != 'pc' && winW >= 1280){ //PC
          winWChk = 'pc';
          // removeActiveClasses(entry)
          itemsPerRow = 3;
      }
      // console.log("active!!")
       
      let rows = Math.ceil(motionType1Items.length / itemsPerRow);
      for (let i = 0; i < rows; i++) {
        let start = i * itemsPerRow;
        let end = start + itemsPerRow;
        let rowItems = Array.from(motionType1Items).slice(start, end); 
        setTimeout(() => {
          rowItems.forEach((item, index) => {
            item.classList.add('active'); 
          });
        }, i * 400); 
      }
      
   
    }
    function removeActiveClasses(entry) {
      // console.log(entry)
      const motionType1Target = entry.target;
      const motionType1TargetItems = motionType1Target.querySelectorAll('.item');
      motionType1TargetItems.forEach((target)=>{
        target.classList.remove('active');
      });
    }
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.03,
    };
    
    const target = document.querySelectorAll('.motion_type1');
    
    const observer = new IntersectionObserver((entries, observer) => {
        
        //console.log($(entries).hasClass("disableoutmotion"),"@@@");
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            console.log("on container")
            //console.log($(entry.target).hasClass("disableoutmotion"),"@")
            showMotion(entry);
          } else {
            // console.log("not container")
            if(!$(entry.target).hasClass("disableoutmotion")){
                removeActiveClasses(entry);  
            }
            //removeActiveClasses(entry);
            // entry.target.classList.remove("on");
          }
        });
        
    }, options);
    if(target != null ) {
      target.forEach((content) => {
          observer.observe(content);
      });
    }
  }
  ,motionType2: function() {
    function showMotion(entry,status) {
      const motionType2 = entry.target;
      const motionType2Items = motionType2.querySelectorAll('.item');
   
      if(status == 'up') {
        for (let i = motionType2Items.length - 1; i >= 0; i--) {
          // console.log(i * 150)
          setTimeout(() => {
            motionType2Items[i].classList.remove('ready_up'); 
            motionType2Items[i].classList.remove('ready_down'); 
            // console.log(motionType2Items[i])
            motionType2Items[i].classList.add('active_up'); 
          }, (motionType2Items.length - 1 - i) * 150); 
        }
      }
      else if(status == 'down') {
          for (let b = 0; b < motionType2Items.length;  b++) {
            motionType2Items[b].classList.remove('ready_up'); 
            motionType2Items[b].classList.remove('ready_down');
            // console.log(b)
            setTimeout(() => {
              motionType2Items[b].classList.add('active_down'); 
            }, b * 150); 
          }
      }
      else {
        for (let b = 0; b < motionType2Items.length;  b++) {
          motionType2Items[b].classList.remove('ready_up'); 
          motionType2Items[b].classList.remove('ready_down');
          // console.log(b)
          setTimeout(() => {
            motionType2Items[b].classList.add('active_down'); 
          }, b * 150); 
        }
        
      }
     
      
   
    }
    function removeActiveClasses(entry, status) {
      // console.log(entry)
      const motionType2Target = entry.target;
      const motionType2TargetItems = motionType2Target.querySelectorAll('.item');
      if(status == 'up') {
        motionType2TargetItems.forEach((target)=>{
          target.classList.remove('active_up');
          target.classList.remove('active_down');
          target.classList.add('ready_up');
        });
      }
      else if(status == 'down') {
        motionType2TargetItems.forEach((target)=>{
          target.classList.remove('active_up');
          target.classList.remove('active_down');
          target.classList.add('ready_down');
        });
      }
      else {
        motionType2TargetItems.forEach((target)=>{
          target.classList.remove('active_up');
          target.classList.remove('active_down');
          target.classList.add('ready_down');
        });
      }
      
    }
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };
    let lastScrollY = window.scrollY;
    let scrollStatus = ''; 

    const motion2 = document.querySelectorAll('.motion_type2');
    const observerType2 = new IntersectionObserver((entries, observer) => {
    //    console.log(entries)
        entries.forEach((entry) => {
          if (window.scrollY > lastScrollY) {
            scrollStatus = 'down';  
          } else if (window.scrollY < lastScrollY) {
            scrollStatus = 'up';   
          }
      
          lastScrollY = window.scrollY;  
      
          if (entry.isIntersecting) {
            
            showMotion(entry, scrollStatus);
          } else {
            removeActiveClasses(entry, scrollStatus);
          }
        });
        
    }, options);

    
    if(motion2 != null ) {
      motion2.forEach((content) => {
        observerType2.observe(content);
      });
    }

  }
  ,motionType3: function() {
    function showMotion(entry) {
      // console.log(entry)
      const motionType3 = entry.target;
   
      
      motionType3.classList.add('active'); 
   
    }
    function removeActiveClasses(entry) {
      // console.log(entry)
      const motionType3Target = entry.target;
      
      motionType3Target.classList.remove('active');
    }
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0,
    };

    const motion3 = document.querySelectorAll('.motion_type3');
    const observerType3 = new IntersectionObserver((entries, observer) => {
    //    console.log(entries)
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            showMotion(entry);
          } else {
            removeActiveClasses(entry);
          }
        });
        
    }, options);

    
    if(motion3 != null ) {
      motion3.forEach((content) => {
        observerType3.observe(content);
      });
    }

  }
  ,motionType4: function() {
    function showMotion(entry) {
      const motionType4 = entry.target;
      const motionType4Items = motionType4.querySelectorAll('.item');
   
      for (let i = 0; i < motionType4Items.length;  i++) {
        setTimeout(() => {
          motionType4Items[i].classList.add('active'); 
        }, i * 130); 
      }
   
    }
    function removeActiveClasses(entry) {
      // console.log(entry)
      const motionType1Target = entry.target;
      const motionType1TargetItems = motionType1Target.querySelectorAll('.item');
      motionType1TargetItems.forEach((target)=>{
        target.classList.remove('active');
      });
    }
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0,
    };

    const motion4 = document.querySelectorAll('.motion_type4');
    const observerType4 = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            showMotion(entry);
          } else {
            removeActiveClasses(entry);
          }
        });
        
    }, options);

    
    if(motion4 != null ) {
      motion4.forEach((content) => {
        observerType4.observe(content);
      });
    }

  }
}
const slideEvents = {
  init: function(){
      this.slideType1();
      this.slideType2();
      this.slideType4();
  }
  ,slideType1: function() {
    let largeSliders = document.querySelectorAll('.slide_type1');
    let prevArrow = document.querySelectorAll('.swiper-button-prev');
    let nextArrow = document.querySelectorAll('.swiper-button-next');
    let pagination = document.querySelectorAll('.swiper-pagination');
    largeSliders.forEach((slider, index)=>{
      let sliderLength = slider.children[0].children.length;
      let result = (sliderLength > 1) ? true : false
      let swiper = new Swiper(slider, {
        slidesPerView: 'auto',
        direction: 'horizontal',
        loop: result,
        pagination: {
          el: pagination[index],
          type: "fraction",
        },
        navigation: {
          // the 'index' bit below is just the order of the class in the queryselectorAll array, so the first one would be NextArrow[0] etc
          nextEl: nextArrow[index],
          prevEl: prevArrow[index],
        },
        speed: 1000,
      });
    
    });
  }
  ,slideType2: function() {
    let largeSliders = document.querySelectorAll('.slide_type2');
    let prevArrow = document.querySelectorAll('.slide_type2_prev');
    let nextArrow = document.querySelectorAll('.slide_type2_next');

    largeSliders.forEach((slider, index)=>{
      let sliderLength = slider.children[0].children.length;
      let result = (sliderLength > 1) ? true : false
      let swiper = new Swiper(slider, {
        slidesPerView: 1,
        navigation: {
          // the 'index' bit below is just the order of the class in the queryselectorAll array, so the first one would be NextArrow[0] etc
          nextEl: nextArrow[index],
          prevEl: prevArrow[index],
      }, 
      });
    
    });
  }
 ,slideType4: function() {
  let largeSliders = document.querySelectorAll('.slide_type4');
  let prevArrow = document.querySelectorAll('.slide_type4_prev');
  let nextArrow = document.querySelectorAll('.slide_type4_next');
  largeSliders.forEach((slider, index)=>{
    let sliderLength = slider.children[0].children.length;
    let result = (sliderLength > 1) ? true : false
    let swiper = new Swiper(slider, {
      slidesPerView: 1,
      effect: 'fade',
       fadeEffect: {
           crossFade: true
       },
      loop: true,
      navigation: {
        // the 'index' bit below is just the order of the class in the queryselectorAll array, so the first one would be NextArrow[0] etc
        nextEl: nextArrow[index],
        prevEl: prevArrow[index],
      },
    });
  
  });

 }
 
}
document.addEventListener("DOMContentLoaded", function(){
    UIeventEB.init();
    motionEvent.init();
    slideEvents.init();
    const links = document.querySelectorAll('a[href="#"]');
    links.forEach(function(element) {
      element.addEventListener('click', function(e) {
        e.preventDefault();
      }, false);
    });

    
    // $('img[usemap]').rwdImageMaps();
});