/* 참고 : https://drg2524.tistory.com/163 */


let inquiryTable = {
    val:{
        table:null
    }
    ,init:function(){
        var that = this;
        console.log("inquery table init")
        // that.val.table = $("#inquiryTable02").DataTable({
        //         order:[0], //기본 정렬 없이
        //         columns: [
        //             {
        //               data: null,
        //               render: function (data, type, row, meta) {
        //                 return meta.row + 1;
        //               },
        //             },
        //             { data: '1' },
        //             { data: '2' },
        //             { data: '3' },
        //             { data: '4' },
        //             { data: '5' },
        //             { data: '6' },
        //             { data: '7' },
        //             { data: '8' },
        //             { data: '9' },
        //             { data: '10' },
        //         ],
        //         columnDefs: [
        //             // 0번째 항목 넓이를 30px로 설정
        //             { targets: 0, width: 50 }
        //         ],
        //         info: false, //정보표시
        //         ordering: true, //정렬기능
        //         lengthChange: false,
        //         searching: true,
        //         paging: true
        //     });
        that.val.table = $("#inquiryTable02").DataTable({
            order:[0], //기본 정렬 없이
            columnDefs: [
                // 0번째 항목 넓이를 30px로 설정
                { targets: 0, width: 60 }
            ],
            info: false, //정보표시
            ordering: true, //정렬기능
            lengthChange: false,
            searching: false,
            paging: true,
        }); 
    }
    ,getBrandList:function(brandid){
        return new Promise(function(resolved,rejected){

            axios.get(`/api/landkoreaPartPriceInquiryGetbrandTojson?brand=${brandid}`)
            .then((result)=>{
                //alert('접수가 완료되었습니다. 확인 후 이메일로 답변드리겠습니다.')
                resolved(result.data);
            })
            .catch((error)=>{
                rejected()
            }) 

        });
    }
    ,getData:function(option){
        var that = this;
        var company = option.company || "";
        var brand = option.brand || "";
        var model = option.model || "";
        var part = option.part || "";
        that.val.table.clear().draw();
        const geturl = `/api/landkoreaPartPriceInquiry?company=${company}&brand=${brand}&model=${model}&part=${part}`
        console.log(geturl,"호출")
        
        axios.get(geturl)
        .then((result)=>{
            //alert('접수가 완료되었습니다. 확인 후 이메일로 답변드리겠습니다.')
            console.log(result.data.posts.length,"-====-");

            if(!result.data.posts || !result.data.posts.length){
                $(".partnumshow").html("0개")
                return;
            }
            
            (result.data.posts).forEach(val=>{
                
                //console.log(val);
                for(var i = 0;i<val.oem_list.length;i++){
                    //let comname = val.companydata[0].company_name; //회사명
                    //let brandstr = val.branddata[0].brand; //브랜드
                    //let brandstr ="?"; //브랜드
                    // let modelname = val.modeldata[0].model_name; //모델명
                    // let partname = String(val.partdata[0].text).split("*^/!*")[0]; // 부품명
                    // let make_number = val.make_number; //부품번호
                    // let type = val.type; //구분
                    let comname = (val.companydata && val.companydata.length) ?  val.companydata[0].company_name : '-'; //회사명
                    let brandstr = (val.branddata && val.branddata.length) ? val.branddata[0].brand : '-'; //브랜드
                    let modelname = (val.modeldata && val.modeldata.length) ? val.modeldata[0].model_name : '-'; //모델명
                    let partname = (val.partdata && val.partdata.length) ? String(val.partdata[0].text).split("*^/!*")[0] : '-'; // 부품명
                    let make_number = val.make_number; //부품번호

                    let type = val.type; //구분


                    let oemdata = val.oem_list[i];
                    let oemnumber = oemdata.oem || "-"
                    let oem_price = oemdata.oem_price || "-"
                    let price = oemdata.price || "-"
                    console.log(oemdata);
                    that.addRow(['1',comname,brandstr,modelname,partname,type,make_number,oemnumber,oem_price,price])
                }
            })
            $(".partnumshow").html(result.data.posts.length+"개")

        })
        .catch((error)=>{
            console.error(error);
            alert('서버접속 에러, 잠시후 다시 시도해주세요.')
        }) 
    }
    ,addRow:function(row){
        var that = this;
        // try{
        //     that.val.table.clear();
        // }catch(err){
        //     console.log(err);
        // }

        // let data = {
        //     1:"1"
        //     ,2:"2"
        //     ,3:"2"
        //     ,4:"2"
        //     ,5:"2"
        //     ,6:"2"
        //     ,7:"2"
        //     ,8:"2"
        //     ,9:"2"
        //     ,0:"2"
        // }

        // that.val.table.rows.add(data).draw();

        that.val.table.row.add(row).draw()
                        
        
        console.log("table clear");
    }
}